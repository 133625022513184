#chatall {
  display: flex;
  justify-content: center;
  /* height: 200px; */
  margin: 0;
}

.chat-container {
  display: flex;
  flex-direction: column;
  width: 650px;
  padding: 10px;
  min-height: 577px;
  background-color: #fff; 
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
}

.user {
  justify-content: flex-end;
}

.user-buttons {
  display: flex;
  justify-content: flex-end;
}
.bot-message{
  position: relative;
  margin-left: 20px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #A8DDFD;
  min-width: 200px;
  min-height: 50px;
  text-align: left;
  font: 400 .9em 'Open Sans', sans-serif;
  border: 1px solid #97C6E3;
  border-radius: 10px;
}


.bot-message:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-top: 15px solid #A8DDFD;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  top: 0;
  left: -15px;
}

.bot-message:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-top: 17px solid #97C6E3;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  top: -1px;
  left: -17px;
}



.button-send {
  display: flex;
  align-items: center;
  font-family: inherit;
  font-weight: 500;
  font-size: 16px;
  padding: 0.7em 1.4em 0.7em 1.1em;
  color: white;
  background: linear-gradient(0deg, 	#52afec, 	#52afec );
  border: none;
  box-shadow: 0 0.7em 1.5em -0.5em rgba(212, 85, 6, 0.596);
  letter-spacing: 0.05em;
  border-radius: 20em;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin: 2px;
  margin-top: 20px;
}


.user-input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto; /* Set margin-top to auto to push it to the bottom */
}

.user-input {
  flex: 1 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 20px;
  font-size: 20px;
  color: #000;
  background-color: #f4f4f4;
  transition: border-color 0.3s, background-color 0.3s;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;
}


.user-input:focus {
  border-color: #4CAF50;
  background-color: aliceblue;
}

.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite; /* Use the spin animation with a duration of 1 second */
  margin: 10px auto; /* Adjust margin as needed */
}

/* Define the spin animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.loader.visible {
  opacity: 1; /* Set opacity to 1 when the loader is visible */
}

.message-content {
  padding: 0;
  margin: 0;
  color: black;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: medium;
}



.profile-user-image
{
  float: right;
  margin-top: 30px;
}
.message-orange {
  position: relative;
  padding: 10px;
  background-color: #f8e896;
  min-height: 50px;
  text-align: right;
  font: 400 .9em 'Open Sans', sans-serif;
  border: 1px solid #dfd087;
  border-radius: 10px;
  margin-top: 5px;
  margin-right: 40px;
}
.message-orange:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: 15px solid #f8e896;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  bottom: 0;
  right: -15px;
}

.message-orange:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: 17px solid #dfd087;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  bottom: -1px;
  right: -17px;
}





#chatDiv {
  overflow-y: scroll;
  scroll-behavior: smooth;
  scrollbar-width: thin;
}

#chatDiv:hover {
  scrollbar-width: thin;
}

#chatDiv::-webkit-scrollbar {
  width: 10px;
}
#chatDiv::-webkit-scrollbar-thumb {
  /* background-color: #cf3c0218;  */
  border-radius: 6px; 
}

#chatDiv::-webkit-scrollbar-track {
  background-color: #f3f3f3; 
  visibility: hidden;
}